











































import { PropType, defineComponent } from '@vue/composition-api';
import GcDivider from '../components/primitives/GcDivider.vue';
import { StepDefinition } from '../interfaces/step-definition';
import { AssistantContext } from '../config/context';

export default defineComponent({
  components: {
    GcDivider,
  },
  props: {
    context: {
      required: true,
      type: Object as PropType<AssistantContext>,
    },
    currentStep: {
      required: true,
      type: Number,
    },
    done: {
      default: false,
      type: Boolean,
    },
    showLabels: {
      default: false,
      type: Boolean,
    },
    steps: {
      required: true,
      type: Array as () => Array<StepDefinition>,
    },
  },
  setup(props, { emit }) {
    function checkVisibility(step: StepDefinition) {
      return step.visiblityCondition
        ? step.visiblityCondition(props.context)
        : true;
    }

    function backToStep(step: StepDefinition) {
      if (!checkVisibility(step) || props.done) {
        return;
      }
      emit('transition', {
        event: `BACK_TO_${step.id.toUpperCase()}`,
      });
    }

    return {
      backToStep,
      checkVisibility,
    };
  },
});
